<template>
<div class="about">
  <div class="index myindex">

    <!-- 轮播图 -->
    <!-- Swiper -->
    <div class="swiper-container backgroundfff">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="../assets/TopImg1.png" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/xiao.png" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/bak.png" />
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>

  </div>

</div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import {
  detailsIntroduction
} from "../api/phonepage";
export default {
  data() {
    return {
      imageList: [
        1, 2, 3
      ]
    };
  },
  components: {},
  mounted: function () {
    this.$nextTick(() => {
      this.swiperAnlie()
    })
  },
  methods: {
    swiperAnlie() {
      this.mySwiperSwiperAnlie = new Swiper('.swiper-container', {
        slidesPerView : '5',  
      })
      // console.log(mySwiper)
    },

  }
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  height: 20.375rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  width: 50%;
  height: 100%;
}
</style>
